import { Injectable } from '@angular/core';
import { DatastoreService } from './datastore.service';
import { RegisterDatastoreService } from '../registration-widget/src/service/register-datastore.service';
import { getCompanyNumber } from '../common/util/header-util';
import { StorageService } from './storage.service';
import { DataService } from '@wss/complaints-widget/src/services/data.service';
import * as moment from 'moment';
import { maskAgreementNumber } from '@wss/common/util/util';


@Injectable({
  providedIn: 'root',
})
export class RequestService {

  constructor(
    private datastoreService: DatastoreService,
    private registerDatastoreService: RegisterDatastoreService,
    private storageService: StorageService,
    private formDataService: DataService,
  ) { }

  private reqOTPbody: any = {};
  private reqOTPVerifybody: any = {};
  private reqCustomerConfirmbody: any = {};
  private questions: any[] = [];
  private reqIIQbody: any = {};
  private reqIIQConfirmbody: any = {};
  private requestPayloadPaymentData: any = {};
  private accountDetails: any = this.datastoreService.getAccountDetails();
  private settlementDetails: any = {};
  private reqMultiCust: any = {};
  private requestPayloadPaymentEmailData: any = {};
  private PaymentDetails: any = {};
  customerDetail: any = this.storageService.getLocalStorage('customerDetail');
  private requestPayloadOverpaymentData: any = {};
  private requestSettlementEmailData: any = {};
  private eocOptions: any = {
    is_collect: false,
    is_part_exchange: false,
    is_return: false
  }
  formData: any = [];
  complaintsFulfilmentmentBody: any = {};
  complaintsAcknowledgementBody: any = {}

  setReqSendOptions() {
    this.reqOTPbody = {
      'customer_token': this.registerDatastoreService.getCustToken()
    }
  }

  getReqSendOptions() {
    return this.reqOTPbody;
  }
  setMultiCustReq(agreement: string) {
    this.reqMultiCust = {
      'customers': this.registerDatastoreService.getCustomerToken(),
      'account_number': agreement
    }
  }

  getMultiCustReq() {
    return this.reqMultiCust;
  }

  setReqVerifyOTP(passcode: string) {
    this.registerDatastoreService.setPasscode(passcode);
    this.reqOTPVerifybody = {
      'passcode': passcode
    }
  }

  getReqVerifyOTP() {
    return this.reqOTPVerifybody;
  }

  getPostCardPaymentReqBody() {
    let companyNumber = getCompanyNumber();
    let paymentRequestData = this.datastoreService.getPaymentRequestPayLoad();
    let merchantCode: any = "1";
    this.accountDetails = this.datastoreService.getAccountDetails()
      ? this.datastoreService.getAccountDetails() : JSON.parse(this.accountDetails);
    if (this.accountDetails && this.accountDetails?.in_arrears) {
      merchantCode = "2";
    }

    this.requestPayloadPaymentData = {
      "transaction_value": paymentRequestData.transaction_value,
      "transaction_date": paymentRequestData.transaction_date,
      "reference_number": this.datastoreService.getAccountNo(),
      "surname": paymentRequestData.surname,
      "date_of_birth": paymentRequestData.date_of_birth,
      "post_code": paymentRequestData.post_code,
      "company_number": companyNumber,
      "verification_number": paymentRequestData.verification_number,
      "token_number": paymentRequestData.token_number,
      "register_token": paymentRequestData.register_token,
      "merchant_code": merchantCode,
      "one_off_card_payment": paymentRequestData.one_off_card_payment,
      "return_url": paymentRequestData.return_url,
      "customer": paymentRequestData.customer
    }
    return this.requestPayloadPaymentData;
  }

  setOTPConfirm() {
    this.reqCustomerConfirmbody = {
      'registration': {
        'option': 'OTP',
        'passcode': this.registerDatastoreService.getPasscode()
      },
      'credentials': {
        'alias': this.registerDatastoreService.getEmail(),
        'password': this.registerDatastoreService.getPwd()
      }
    }
  }

  setReqIIQ() {
    this.reqIIQbody = {
      'registration': {
        'option': "IIQ",
        'transaction_id': this.registerDatastoreService.getTransactionIdIIQ(),
        'questions_asked': this.registerDatastoreService.getQuestionAsked(),
        'questions_correct': this.registerDatastoreService.getQuestionsCorrect(),
        'questions': this.getQuestionAns()
      }
    }
  }

  setIIQConfirm() {
    this.reqIIQConfirmbody = {
      'status': this.registerDatastoreService.getStatusIIQ(),
      'registration': {
        'option': 'IIQ',
        'passcode': this.registerDatastoreService.getPassCodeIIQ(),
        'transaction_id': this.registerDatastoreService.getTransactionIdIIQ(),
        'questions_asked': this.registerDatastoreService.getQuestionAsked(),
        'questions_correct': this.registerDatastoreService.getQuestionsCorrect()
      },
      'credentials': {
        'alias': this.registerDatastoreService.getEmail(),
        'password': this.registerDatastoreService.getPwd()
      }
    }
  }

  getIIQConfirm() {
    return this.reqIIQConfirmbody;
  }

  getOTPConfirm() {
    return this.reqCustomerConfirmbody;
  }

  getReqIIQ() {
    return this.reqIIQbody;
  }

  setQuestionAns(value: any[]) {
    this.questions = value;
  }

  getQuestionAns() {
    return this.questions;
  }

  setSettlementDetails(newSettlementFlag?: any) {
    const accDetails = this.datastoreService.getAccountDetails()
      ? this.datastoreService.getAccountDetails() : this.accountDetails;
    const customerDetail = this.datastoreService.getCustomerDetail()
      ? this.datastoreService.getCustomerDetail() : this.customerDetail;
    if (accDetails && customerDetail) {
      this.settlementDetails = {
        registration_no: accDetails?.registration_no,
        surname: customerDetail?.individual.surname,
        company_name: "",
        post_code: customerDetail?.addresses.address_detail.post_code,
        date_of_birth: customerDetail?.individual.date_of_birth,
        customer_permission_given: true,
        settlement_letter_required: newSettlementFlag ? newSettlementFlag : false
      }
    }
    return this.settlementDetails;
  }

  getSmsConfirmation(templateBody: any) {
    let smsConfirmationBody = {
      recipient_mobile_number: templateBody.mobileNo,
      sms_template_id: templateBody.templateId,
      account_number: this.datastoreService.getAccountNo(),
      list_attributes: '',
    };
    if (templateBody.listAttributes) {
      for (let attrkey in templateBody.listAttributes) {
        smsConfirmationBody.list_attributes += `${attrkey}:${templateBody.listAttributes[attrkey]}|`;
      }
      smsConfirmationBody.list_attributes = smsConfirmationBody.list_attributes.substring(0, smsConfirmationBody.list_attributes.length - 1);
    }
    return smsConfirmationBody;
  }

  geEmailRequestBody(templateBody: any) {
    let emailConfirmationBody = {
      account_number: this.datastoreService.getAccountNo(),
      recipient_email_address: this.datastoreService.getLoggedInEmail(),
      email_title: templateBody.email_title,
      email_subject: templateBody.email_subject,
      list_attributes: '',
    };
    if (templateBody.listAttributes) {
      for (let attrkey in templateBody.listAttributes) {
        emailConfirmationBody.list_attributes += `${attrkey}:${templateBody.listAttributes[attrkey]}|`;
      }
      emailConfirmationBody.list_attributes = emailConfirmationBody.list_attributes.substring(0, emailConfirmationBody.list_attributes.length - 1);
    }
    return emailConfirmationBody;
  }

  getPostPaymentEmailReqBody() {
    let customerMailId;
    customerMailId = this.datastoreService.getLoggedInEmail();
    let paymentDetail = localStorage.getItem("paymentUpdateDetails");
    let customerDetail = this.datastoreService.getCustomerDetail();
    let customerNameFormat = customerDetail.individual.first_name.charAt(0).toUpperCase() + customerDetail.individual.first_name.slice(1).toLowerCase();
    let paymentReceiptNumber = this.datastoreService.getPaymentSuccessReciptNumber();
    paymentDetail = paymentDetail && JSON.parse(paymentDetail);
    this.PaymentDetails = paymentDetail;

    this.PaymentDetails.transaction_value = '£' + this.PaymentDetails.transaction_value;

    let settlementValue = localStorage.getItem('settlementRedirectionFlag');
    let accDetails = this.datastoreService.getAccountDetails()
      ? this.datastoreService.getAccountDetails() : this.accountDetails;
    //  Email payload object condition check for settlement and payment confirmation
    if (accDetails.registration_no !== null && accDetails.registration_no !== "" && settlementValue === 'settlementFlag') {
      this.requestPayloadPaymentEmailData =
      {
        "account_number": this.datastoreService.getAccountNo(),
        "recipient_email_address": customerMailId,
        "email_subject": "SettlementConfirmation",
        "list_attributes": "customerName:" + customerNameFormat + "|vehicleRegistration:" + accDetails.registration_no + "|amount:" + this.PaymentDetails.transaction_value + "|referenceNumber:" + paymentReceiptNumber
      }
    } else {
      this.requestPayloadPaymentEmailData =
      {
        "account_number": this.datastoreService.getAccountNo(),
        "recipient_email_address": customerMailId,
        "email_subject": "Automated Receipt",
        "list_attributes": "customerName:" + customerNameFormat + "|payment_amount:" + this.PaymentDetails.transaction_value + "|receipt_number:" + paymentReceiptNumber
      }
    }
    // + "|open_times:" + openTime
    return this.requestPayloadPaymentEmailData;
  }

  getOverpaymentReqBody() {
    this.requestPayloadOverpaymentData = {
      pes_value: "£" + sessionStorage.getItem('settlementAmount'),
      pes_date: sessionStorage.getItem('overpayment-date') ? sessionStorage.getItem('overpayment-date') : '',
      paid_already: 'N',
      // sessionStorage.getItem('overpayment-date') ? 'N' : 'Y',
      payment_or_term: sessionStorage.getItem('payment-or-term') ? sessionStorage.getItem('payment-or-term') : ''
      // payment_or_term: ''
    }
    return this.requestPayloadOverpaymentData;
  }

  setBannerMessage(errorMessage: any) {
    const bannerMessage: any = {};
    bannerMessage.closeIconClass = 'clear';
    bannerMessage.infoTypeClass = 'icon-warning-red mgn-top-15px';
    bannerMessage.leftSectionColorClass = 'message-failure';
    bannerMessage.titleText = errorMessage;
    bannerMessage.isBannerClosed = false;
    return bannerMessage;
  }

  setKeepVehicleOptionForEoc() {
    this.eocOptions.is_collect = true;
    this.eocOptions.is_return = false;
    this.eocOptions.is_part_exchange = false;
    this.eocOptions.is_extend = false;
  }

  setPartExchangeOptionForEoc() {
    this.eocOptions.is_collect = false;
    this.eocOptions.is_return = true;
    this.eocOptions.is_part_exchange = true;
    this.eocOptions.is_extend = false;
  }

  setReturnVehicleOptionForEoc() {
    this.eocOptions.is_collect = false;
    this.eocOptions.is_return = true;
    this.eocOptions.is_part_exchange = false;
    this.eocOptions.is_extend = false;
  }

  setExtendVehicleOptionForEoc() {
    this.eocOptions.is_collect = true;
    this.eocOptions.is_return = false;
    this.eocOptions.is_part_exchange = false;
    this.eocOptions.is_extend = true;
  }

  getEocOption() {
    return this.eocOptions;
  }

  getSettlementEmailReqBody(settlement_figure: any) {
    let customerMailId = this.datastoreService.getLoggedInEmail();
    let customerDetail = this.datastoreService.getCustomerDetail();
    let customerNameFormat = customerDetail.individual.first_name.charAt(0).toUpperCase() + customerDetail.individual.first_name.slice(1).toLowerCase();
    let accDetails = this.datastoreService.getAccountDetails()
      ? this.datastoreService.getAccountDetails() : JSON.parse(this.accountDetails);
    settlement_figure = this.formatSettlementFigure(settlement_figure);
    this.requestSettlementEmailData = {
      "account_number": this.datastoreService.getAccountNo(),
      "email_subject": "SettlementQuote",
      "email_title": "SettlementQuote",
      "list_attributes": "customerName:" + customerNameFormat + "|vehicleRegistration:" + accDetails.registration_no + "|amount:" + "£" + settlement_figure + "|settlementDate:" + localStorage.getItem('settlementExpiryDate') + "|lastPaymentDate:" + accDetails.last_payment_date + "|lastPaidAmount:" + accDetails.last_payment_amount,
      "recipient_email_address": customerMailId,
    }
    return this.requestSettlementEmailData;
  }

  getSettlementEmail3rdPartyReqBody(settlement_figure: any, thirdPartyEmail: any) {
    let customerMailId = this.datastoreService.getLoggedInEmail();
    let customerDetail = this.datastoreService.getCustomerDetail();
    let customerNameFormat = this.formatCustomerName(customerDetail);
    let accDetails = this.datastoreService.getAccountDetails()
      ? this.datastoreService.getAccountDetails() : JSON.parse(this.accountDetails);
    settlement_figure = this.formatSettlementFigure(settlement_figure);
    this.requestSettlementEmailData = {
      "account_number": this.datastoreService.getAccountNo(),
      "email_subject": "SettlementQuote3rdParty",
      "email_title": "SettlementQuote3rdParty",
      "list_attributes": "customerName:" + customerNameFormat + "|vehicleRegistration:" + accDetails.registration_no + "|amount:" + "£" + settlement_figure + "|settlementDate:" + localStorage.getItem('settlementExpiryDate') + "|lastPaymentDate:" + accDetails.last_payment_date + "|lastPaidAmount:" + accDetails.last_payment_amount + "|accountNumber:" + accDetails.account_number,
      "recipient_email_address": thirdPartyEmail,
      "cc_email_address": customerMailId
    }
    return this.requestSettlementEmailData;
  }

  formatSettlementFigure(settlement_figure: any) {
    let endsWithZeroAfterDecimal = (settlement_figure.indexOf(".") !== -1) && settlement_figure.endsWith("0");
    if (!settlement_figure.endsWith(".00")) {
      settlement_figure = Number(settlement_figure).toLocaleString();
      settlement_figure = endsWithZeroAfterDecimal ? settlement_figure + "0" : settlement_figure;
      settlement_figure = settlement_figure.indexOf(".") === -1 ? settlement_figure + '.00' : settlement_figure;
    }
    else {
      settlement_figure = Number(settlement_figure).toLocaleString() + '.00';
    }
    return settlement_figure;
  }

  formatCustomerName(customerDetail: any) {
    let title = customerDetail.individual.title.charAt(0).toUpperCase() + customerDetail.individual.title.slice(1).toLowerCase();
    let firstName = customerDetail.individual.first_name.charAt(0).toUpperCase() + customerDetail.individual.first_name.slice(1).toLowerCase();
    let surname = customerDetail.individual.surname.charAt(0).toUpperCase() + customerDetail.individual.surname.slice(1).toLowerCase();
    return `${title} ${firstName.charAt(0).toUpperCase()} ${surname.charAt(0).toUpperCase()}xxxxxxxxxx`;
  }

  getComplaintsFormData(complaintConcernType: string, complaintsRecipientEmailAddress: string, complaintsEmailAddress: string) {
    const today = moment().format('DD/MMM/YYYY HH:mm');
    this.accountDetails = this.datastoreService.getAccountDetails()
      ? this.datastoreService.getAccountDetails() : JSON.parse(this.accountDetails);
    this.formData = this.formDataService.getComplaintsFormData();
    let complaintNature: string = "";
    let complaintNatureDetails: string = "";
    let email_title: string = "";
    let email_subject: string = "";
    let aboutYourVehicle: string = "";
    let currentMileage: string = "";
    let infoAboutDealersResolution: string = "";
    let vehicleFault: string = "";
    let potentailSupportNeed: string = "";
    let investigateAndUnderstandComplaint: string = "";
    let pcpMileageAllowance: number = 0;
    let preferredContactMethod = "";
    let note1 = "Financial impact : This field has been digitally updated as the customer has logged a complaint on WSS.  Please discuss with the customer to understand any impact in this area and update the field accordingly.";
    let note2 = "";
    let note3 = "";
    let arrearsAmount = parseFloat(this.accountDetails.arrears_plus_fees?.toString().replace('£', '').replace(',', '')).toFixed(2);
    if (this.formData.complaintYourContactDetailsForm.complaintPreferredContactMethod == 'Phone') {
      preferredContactMethod = this.formData.complaintYourContactDetailsForm.complaintPreferredContactTime
    } else {
      preferredContactMethod = this.formData.complaintYourContactDetailsForm.complaintPreferredContactMethod
    }
    if (this.formData.complaintYourContactDetailsForm.evidenceDocumentsShared == 'Yes') {
      note3 = "Customer has emailed Evidence and/or document/s to " + complaintsEmailAddress
    } else {
      note3 = "On the digital form customer has opted not to share any Evidence and/or document/s via email"
    }
    if (this.formData.complaintYourContactDetailsForm.needsAdditionalSupport == "Yes") {
      potentailSupportNeed = '|potentailSupportNeed:No';
      note2 = 'Potential Support Need : On the digital form customer has called out a potential support need. Please call the customer, follow TEXAS and correctly update support need/s on the ‘Customer Details’ tab'
    } else {
      potentailSupportNeed = '|potentailSupportNeed:' + this.formData.complaintYourContactDetailsForm.needsAdditionalSupport
      note2 = 'Potential Support Need : On the digital form customer has said they do not have any potential support need'
    }
    if (this.formData.complaintRelateToSelectionForm.speakWithSomeElseOption == 'Yes') {
      investigateAndUnderstandComplaint = '|investigateAndUnderstandComplaint:3rd party authorisation - Yes - ' + this.formData.complaintRelateToSelectionForm.contactDetail.replaceAll('\n', '<br/>') + '<br/>'
    } else {
      investigateAndUnderstandComplaint = '|investigateAndUnderstandComplaint:No 3rd party authorisation <br/>'
    }
    if (this.accountDetails.max_total_mileage) {
      pcpMileageAllowance = Number(this.accountDetails.max_total_mileage);
    }
    if (complaintConcernType == 'Vehicle') {
      email_title = "WSS Complaint Digitisation SQD";
      email_subject = "ComplaintDigitisationSQD";
      complaintNature = 'SQD>Quality Disputes>';
      if (this.formData.complaintAboutYourVehicleSelectionForm.anyIssuesDiagnosedOption == 'Yes') {
        aboutYourVehicle = 'Issues Diagnosed - ' + this.formData.complaintAboutYourVehicleSelectionForm.whatAndWhenIssueDiagnosed.replaceAll('\n', '<br/>') + '<br/>'
      } else {
        aboutYourVehicle = 'Issues not Diagnosed <br/>'
      }
      if (this.formData.complaintAboutYourVehicleSelectionForm.anyGarageOtherThanSupplyingOption == 'Yes') {
        if (this.formData.complaintAboutYourVehicleSelectionForm.authoriseToSpeakToGarage == 'Yes') {
          aboutYourVehicle = aboutYourVehicle + 'Details on other Garage/s - ' + this.formData.complaintAboutYourVehicleSelectionForm.garageContactDetails.replaceAll('\n', '<br/>') + '<br/>'
        } else {
          aboutYourVehicle = aboutYourVehicle + 'No authorisation to speak to other Garage/s <br/>'
        }
      } else {
        aboutYourVehicle = aboutYourVehicle + 'Vehicle not been to other Garages <br/>'
      }
      if (this.formData.complaintTellUsSelectionForm.dealerAskedYouToContactOption == 'Yes' && this.formData.complaintRelateToSelectionForm.concernsRaiseWithDealerOption == 'Yes') {
        complaintNatureDetails = "Dealer Directed Customer to BH>Faulty Goods";
      } else if (this.formData.complaintTellUsSelectionForm.dealerAskedYouToContactOption == 'Yes' && this.formData.complaintRelateToSelectionForm.concernsRaiseWithDealerOption == 'No') {
        complaintNatureDetails = "No prior contact with dealer about the concerns>Faulty Goods";
      } else if (this.formData.complaintTellUsSelectionForm.dealerAskedYouToContactOption == 'No' && this.formData.complaintRelateToSelectionForm.concernsRaiseWithDealerOption == 'Yes') {
        complaintNatureDetails = "Prior contact with dealer but did not direct customer to BH>Faulty Goods";
      } else {
        complaintNatureDetails = "No prior contact with dealer about the concerns>Faulty Goods";
      }
      aboutYourVehicle = aboutYourVehicle + 'Vehicles current location - ' + this.formData.complaintAboutYourVehicleSelectionForm.whereVehicleLocatedTextarea.replaceAll('\n', '<br/>') + '<br/>'
        + 'Vehicle drivable / safe to use - ' + this.formData.complaintAboutYourVehicleSelectionForm.vehicleDriveableOrSafeSelection + '<br/>'
      currentMileage = '|currentMileage:' + this.formData.complaintAboutYourVehicleSelectionForm.mileage;
      infoAboutDealersResolution = this.formData.complaintTellUsSelectionForm.dealerOfferedYouResolution == 'Yes' ? 'Resolution offered by dealer - ' + this.formData.complaintTellUsSelectionForm.moreInfoAboutDealersResolution.replaceAll('\n', '<br/>') + '<br />' : 'No resolution offered by dealer - '
        + this.formData.complaintTellUsSelectionForm.moreInfoAboutDealersResolution.replaceAll('\n', '<br/>') + '<br />';
      vehicleFault = '|vehicleFaults:' + this.formData.complaintRelateToSelectionForm.selectedCategories;
    } else {
      email_title = "WSS Complaint Digitisation Servicing";
      email_subject = "ComplaintDigitisationServicing";
      complaintNature = 'Servicing>' + this.formData.complaintRelateToSelectionForm.complaintRelateToSelection + '>' + this.formData.complaintRelateToSelectionForm.whatWentWrongComplaintSelection;
    }
    this.complaintsFulfilmentmentBody = {
      account_number: this.datastoreService.getAccountNo(),
      recipient_email_address: complaintsRecipientEmailAddress,
      email_title: email_title,
      email_subject: email_subject,
      list_attributes: 'agreementNumber:' + this.datastoreService.getAccountNo() + potentailSupportNeed + '|preferredContactMethod:' + preferredContactMethod +
        '|arrearsAmount:' + arrearsAmount + '|pcpMileageAllowance:' + pcpMileageAllowance +
        currentMileage + '|complaintDetail:' + this.formData.complaintTellUsSelectionForm.issueYouHaveExperiencedTextArea.replaceAll('\n', '<br/>') + '<br />' +
        this.formData.complaintTellUsSelectionForm.summaryOfImpactTextarea.replaceAll('\n', '<br/>') + '<br />' + this.formData.complaintTellUsSelectionForm.howWouldYouLikeToResolveComplaintTextarea.replaceAll('\n', '<br/>') + '<br />' +
        '|complaintNature:' + complaintNature + complaintNatureDetails + investigateAndUnderstandComplaint +
        infoAboutDealersResolution + aboutYourVehicle + vehicleFault + '|note1:' + note1 + '|note2:' + note2 + '|note3:' + note3 +
        '|complaintResolved:' + 'No' + '|dateAndTime:' + today
    };
    return this.complaintsFulfilmentmentBody;
  }

  getComplaintsAcknowledgementData(complaintConcernType: string, complaintsEmailAddress: string, complaintsRecipientEmailAddress: string) {
    this.accountDetails = this.datastoreService.getAccountDetails()
      ? this.datastoreService.getAccountDetails() : JSON.parse(this.accountDetails);
    const complaintType = complaintConcernType == 'Vehicle' ? 'Vehicle complaint' : 'Complaint about something else'
    this.complaintsAcknowledgementBody = {
      account_number: this.accountDetails?.account_number,
      email_title: "Complaint Digitisation Acknowledgement",
      email_subject: "ComplaintDigitisationAcknowledgement",
      recipient_email_address: complaintsRecipientEmailAddress,
      list_attributes: 'agreementNumber:' + maskAgreementNumber(this.datastoreService.getAccountNo())
        + '|complaintType:' + complaintType + '|complaintsEmailId:' + complaintsEmailAddress
    };
    return this.complaintsAcknowledgementBody;
  }
}