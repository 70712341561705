import { Injectable, Inject } from '@angular/core';
import { UserDetails } from '../model/userDetails';
import { Account } from '../model/account';
import { AccountList } from '../model/accountList';
import { Users } from '../model/users';
import { isNotEmpOrNullOrUndef, jsonParser } from '../common/util/util';
import { AccountSummary, Arrear, MyAgreement, SpeedometerData, Contract, AdditionalInfo, CarbonIoffsetBanner } from '../account-summary-widget/src/model/account-summary';
import { NextPayment } from '../model/nextPayment';
import { messageOTP } from 'libs/model/messageOtp';
import { Transaction } from '../transaction-widget/src/model/transactions';
import { DirectDebitResponse } from '../payment-widget/src/models/direct-debit';
import { QuestionIIQ } from '../model/questionIIQ';
import { Settlement, SettlementResponse } from 'libs/model/settlementDetails';
import { DueDate } from 'libs/payment-widget/src/models/change-payment';
import { BannerMessage } from '../model/bannerMessage';
import { MessageService } from './message.service';
import { AuthService } from './auth.service';
import { appConfig, RuntimeEnv, vehicleTypes } from '../config/wss-app-constants';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InboxResponse, InboxMsgList } from '@wss/model/content/inbox-msg';
import { BudgetPlanner } from '@wss/budget-cover-widgets/shared/model/budget.model';
import { BalloonRefinanceSavedQuote } from '@wss/model/balloon-refinance/balloon-refinance';
import 'rxjs/add/observable/of';
import { Address, addressList } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})

export class DatastoreService {
  private customerDetail: any = this.storageService.getLocalStorage('customerDetail') || '';
  private accountsDetails: any = this.storageService.getLocalStorage('accountDetail') || '';

  private customer: Users[] = [];
  private userDetails: any;
  private messageOTP: any;
  private customerId: any;
  private name: string = '';
  private firstName: string = '';
  private surName: string = '';
  private initials: string = '';
  private accounts: any;
  private accountList: AccountList[] = [];
  private accountNo: any;
  private accountCount: number = 0;

  private mobileNo: string = '';
  private passcode: string = '';
  private email: string = '';
  private password: string = '';
  private userId: string = '';
  private token: string = '';
  private loginAttempt: number = 0;
  private userName: string = '';
  private customerLogin: any;
  private transactions: Transaction[] = [];
  private directDebitResponse: DirectDebitResponse = new DirectDebitResponse();
  private arrearShow: boolean = true;

  private transactionIdIIQ: string = '';
  private questionAsked: number = 0;
  private questionsCorrect: number = 0;
  private detailsIIQ: any;
  private questionList: any[] = [];
  private statusIIQ: string = '';
  private verifyType: string = '';
  private fwdPwdDetails: any;
  private fwdPwdLink: string = '';
  private fwdPwdToken: string = '';
  private resetLinkData: any;
  private validResetLink: string = '';
  private confirmPwdData: any;
  private confirmPwdStatus: string = '';
  private paymentDetails: any;
  private cardPaymentDetails: any;
  private postUrlData: any;
  private paymentId: any;
  private allPaymentCardDetail: any;
  private paymentCardFirstDetail: any;
  private paymentCardRestDetail: any;
  private paymentPayLoad: any;
  private passcodeIIQ: string = '';
  private settlementDetails: any = {};
  private cardPaymentErrorDetails: any;
  private cardPaymentUpdateDetails: any;
  private cardPaymentResultStatus: any;
  private recipientMobileNumber: any;
  private settlementResponse: any = {};
  private dueDate: DueDate = new DueDate();
  overpaymentDetails: any;
  settlementAmount: string = '';
  private failCount: any;
  bannerMessage: BannerMessage = new BannerMessage();
  private bureauCallAction: string = '';
  private bureauCallFailCount: any = 0;
  private inLogin: boolean = false;
  private pwdReset: boolean = false;
  private paymentReciptNumber: any;
  private currentAccountMailPreference: any;
  private otherAccountsMailPreference: any[] = [];
  private preferenceLaunchThrough: string = "";
  availablePreferences: string[] = ['post', 'paperless', 'multi'];
  mailboxMsg: InboxResponse = new InboxResponse();
  private mailboxMsgList: InboxMsgList[] = [];
  private mailboxUnreadCount = new Subject<number>();

  private budgetDetail:any ={};
  private onOffObject:any = {};
  private budgetSummaryData: any = {}
  private submitBudgetDetail:any = {}
  private isRouteFromArrearsBanner: boolean = false
  private isEocBrResumeAppBannerClosed: boolean = false;
  private isEocLandingPageResumeBrAppBannerClosed: boolean = false;
  private isBrLandingPageResumeBrAppBannerClosed: boolean = false;
  public enterCardDetails = new Subject<any>();
  private isReturnJourneyBrQuotePage: boolean = false;
  private vehicleType: any;
  private vehicleTypeCheck: string = '';
  private errorBrServer: boolean = false;
  
  private myAccount: AccountSummary = {
    arrearData: new Arrear(),
    myAgreementData: new MyAgreement(),
    speedometerData: new SpeedometerData(),
    contractData: new Contract(),
    nextPaymentData: new NextPayment(),
    additionalInfoData: new AdditionalInfo(),
    carbonIoffsetBanner: new CarbonIoffsetBanner()
  };


  payloaddata: any;
  private activeDirectDebitFound: boolean = false;
  bankDetailStatus: string[] = ["valid", "invalid", "none"];
  widgetWrapperMarginTop: string = '29px';
  private availableAdvice: string = "";
  private selectedTermExtensionQuote: any = {};
  private requestedRental: string = "";
  private requestedTerm: string = "";
  private termExtFormDetail: any = null;
  private stepperStep: number = 0;
  private loggedInEmail: any;
  previousPageUrl: any;

  private dealersDeatails: any;
  private isAccountCompleted: any = false;
  private isAccountArchived: any = false;
  public accountSummarySubject = new Subject<any>();
  public accountSummaryData$ = this.accountSummarySubject.asObservable();
  routePageName : any;
  addressChanged: boolean = false;
  changedAddressDetail: any;
  public verifoneUrl: BehaviorSubject<string> = new BehaviorSubject('');
  private contactPreferenceData: any;
  private isContactPreferencesFeature: any;
  private isContactPreferencesRegisterFeature:any;
  private isComplaintFeatureEnabled: any;
  proposalCustomerDetails: any;
  decisionDetails: any;
  calculatedModagDetails: any;

  private BalloonRefinanceQuoteData: BalloonRefinanceSavedQuote = {
    balloonRefinance: 0,
    balloonPayment: 0,
    monthlyPayment: 0,
    termLength: 0,
    optionalFinalPayment: 0,
    totalInterest: 0,
    totalAmount: 0
  }
  public brCurrentPageSubject = new Subject<number>();
  public brCurrentPageData$ = this.brCurrentPageSubject.asObservable();

  public brReturnJourneySubject = new BehaviorSubject<boolean>(false);
  public brReturnJourney$ = this.brReturnJourneySubject.asObservable();

  public brgHideStepperSubject = new BehaviorSubject<boolean>(false);
  public brHideStepper$ = this.brgHideStepperSubject.asObservable();

  public brgDeclinePageSubject = new BehaviorSubject<boolean>(false);
  public brgDeclinePage$ = this.brgDeclinePageSubject.asObservable();

  proposalCustomerPayload: any;

  private proposalDetail: any = localStorage.getItem("proposalDetail");

  private addressData: Address = {
    county: null,
    district: null,
    flat_number: null,
    house_name: null,
    house_number: null,
    post_code: null,
    post_town: null,
    street_name: null
  }

  private proposalEditMode: boolean = false;
  private addressPost: addressList[] = [];
  private unemployedStatus?: number;
  pdfJs: any;
  complaintsFormData: any = {};
  complaintGetStartedContent: any = {};

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private storageService: StorageService,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig
  ) {
    if (isNotEmpOrNullOrUndef(this.customerDetail)) {
      this.customerDetail = jsonParser(this.customerDetail)
    }
    if (isNotEmpOrNullOrUndef(this.accountsDetails)) {
      this.accountsDetails = jsonParser(this.accountsDetails)

      if (this.accountsDetails && this.accountsDetails.method_payment === 'D') {
        this.setActiveDirectDebitFound(true)
      }
      else {
        this.setActiveDirectDebitFound(false);
      }
    }
    if (isNotEmpOrNullOrUndef(this.proposalDetail)) {
      this.proposalDetail = JSON.stringify(this.proposalDetail);
    }
  }

  setVerifoneUrl(url: string): void {
    this.verifoneUrl.next(url);
  }

  getVerifoneUrl(): Observable<string> {
    return this.verifoneUrl.asObservable();    
  }

  getInterstitialPopupShown(){
    return sessionStorage.getItem("insterstitialPopup")? true: false;
  }

  setInterstitialPopupShown(){
    sessionStorage.setItem("insterstitialPopup", "true");
  }

  getPaperlessBannerShown(){
    return sessionStorage.getItem("paperlessBanner")? true: false;
  }

  setPaperlessBannerShown(){
    sessionStorage.setItem("paperlessBanner", "true");
  }

  getIsPreferenceUpdated(){
    return sessionStorage.getItem("preferenceUpdated")? true: false;
  }

  setIsPreferenceUpdated(){
    sessionStorage.setItem("preferenceUpdated", "true");
  }

  getIsAccountCompleted(){
    return sessionStorage.getItem("accountCompleted")? true: false;
  }

  getIsAccountArchived(){
    return sessionStorage.getItem('isAccountArchived') === 'true';
  }
  setIsAccountCompleted(){
    sessionStorage.setItem("accountCompleted", "true");
  }
  setIsAccountArchived(isArchived:boolean){
    if(isArchived){
      sessionStorage.setItem('isAccountArchived', 'true');
    }
    else {
      sessionStorage.setItem('isAccountArchived', 'false');
    }
  }
  setPreferenceLaunchThrough(launchPoint: string){
    this.preferenceLaunchThrough = launchPoint;
    sessionStorage.setItem("preferenceLaunchPoint", this.preferenceLaunchThrough);
  }

  getPreferenceLaunchThrough(){
    this.preferenceLaunchThrough = sessionStorage.getItem("preferenceLaunchPoint") || "";
    return this.preferenceLaunchThrough;
  }

  setCurrentAccountMailPreference(mailPrefObj: any){
    this.currentAccountMailPreference = mailPrefObj;
    sessionStorage.setItem("currentAcctPreference", JSON.stringify(this.currentAccountMailPreference));
  }

  getCurrentAccountMailPreference(){
    this.currentAccountMailPreference = sessionStorage.getItem("currentAcctPreference") || "{}";
    return JSON.parse(this.currentAccountMailPreference);
  }

  setOtherAccountsMailPreference(otherAccounts: any[]){
    this.otherAccountsMailPreference = otherAccounts;
    sessionStorage.setItem("otherAccountsPreference", JSON.stringify(this.otherAccountsMailPreference));
  }

  getOtherAccountsMailPreference(){
    this.otherAccountsMailPreference = sessionStorage.getItem("otherAccountsPreference") || "[]" as any;
    return JSON.parse(this.otherAccountsMailPreference as any);
  }

  setActiveDirectDebitFound(value: boolean) {
    sessionStorage.setItem("activeDirectDebitFound", value + '');
    this.activeDirectDebitFound = value;
  }

  getActiveDirectDebitFound() {
    this.activeDirectDebitFound = sessionStorage.getItem("activeDirectDebitFound") === 'true' ? true : false;
    return this.activeDirectDebitFound;
  }

  setAvailableAdvice(value: string) {
    sessionStorage.setItem("availableAdvice", value);
    this.availableAdvice = value;
  }

  getAvailableAdvice() {
    this.availableAdvice = sessionStorage.getItem("availableAdvice") || "";
    return this.availableAdvice;
  }

  setCustomerDetail(data: any) {
    this.customerDetail = data;
    this.storageService.setLocalStorage('customerDetail', JSON.stringify(this.customerDetail));
  }

  getCustomerDetail() {
    let storedCustomerDetail : any = this.storageService.getLocalStorage('customerDetail') ? this.storageService.getLocalStorage('customerDetail') : this.customerDetail;
    storedCustomerDetail = JSON.parse(storedCustomerDetail);
    return storedCustomerDetail;
  }

  setProposalRequest(data: any) {
    this.proposalDetail = data;
    this.storageService.setLocalStorage('proposalDetail', JSON.stringify(this.proposalDetail));
  }

  getProposalRequest() {
    let storedProposalDetail: any = this.storageService.getLocalStorage('proposalDetail') ? this.storageService.getLocalStorage('proposalDetail') : this.proposalDetail;
    storedProposalDetail = JSON.parse(storedProposalDetail);
    return storedProposalDetail;
  }
  
  setChangedAddressDetail(data: any) {
    this.changedAddressDetail = data;
    localStorage.setItem("changedAddressDetail", JSON.stringify(this.changedAddressDetail));
  }

  getChangedAddressDetail() {
    return this.changedAddressDetail;
  }

  getPayload(value: any) {
    this.payloaddata = value;
    this.customer = value;
  }

  setCustomer(value: Users[]) {
    this.customer = value;
  }

  getCustomers() {
    return this.customer;
  }

  setUserdetails(value: UserDetails) {
    this.userDetails = value;
    this.setPersonalDetails(
      this.userDetails.individual.first_name,
      this.userDetails.individual.surname,
      this.userDetails.mobile_phone_number
    );
  }

  getUserdetails() {
    return this.userDetails;
  }

  setMessageOtp(value: messageOTP) {
    this.messageOTP = value;
    this.setPasscode(this.messageOTP.registration.passcode);
    this.setUserId(this.messageOTP.user_id);
  }

  setPasscode(value: string) {
    this.passcode = value;
  }

  getPasscode() {
    return this.passcode;
  }

  setUserId(value: string) {
    this.userId = value;
  }

  getUserId() {
    return this.userId;
  }

  setCustomerId(value: any) {
    this.customerId = value;
    this.storageService.setLocalStorage('customerId', value);
  }

  getCustomerId() {
    this.customerId = this.storageService.getLocalStorage('customerId');
    return this.customerId;
  }

  setEmail(value: string) {
    this.email = value;
  }

  getEmail() {
    return this.email;
  }

  setPwd(value: string) {
    this.password = value;
  }

  getPwd() {
    return this.password;
  }

  setPersonalDetails(firstName: string, surName: string, mobileno: string) {
    this.firstName = firstName;
    this.surName = surName;
    this.name = this.firstName + ' ' + this.surName;
    this.initials = this.firstName.substring(0, 1) + this.surName.substring(0, 1);
    this.mobileNo = isNotEmpOrNullOrUndef(mobileno) ? mobileno : '';
  }

  getName() {
    return this.name;
  }

  getfirstName() {
    return this.firstName;
  }

  getsurName() {
    return this.surName;
  }

  getInitials() {
    return this.initials;
  }

  getMobileNo() {
    return this.mobileNo;
  }

  setAccounts(accounts: Account) {
    this.accounts = accounts;
    this.setAccountList(this.accounts.accountList);
    this.setAccountCount(this.accounts.countOfAccounts);
    if (this.getAccountCount() === 1) {
      let accNo = this.accounts.accountList[0].href
      this.setAccountNo(accNo.substr(12));
    }
  }

  getAccounts() {
    return this.accounts;
  }

  setAccountList(accountList: AccountList[]) {
    this.accountList = accountList;

    
    sessionStorage.setItem("accountList", JSON.stringify(this.accountList));
  }

  getAccountList() {
    this.accountList = sessionStorage.getItem("accountList") || "[]" as any;
    return JSON.parse(this.accountList as any);
  }

  setAccountCount(accountCount: number) {
    this.accountCount = accountCount;
  }

  getAccountCount() {
    return this.accountCount;
  }

  setAccountNo(accountNo: any) {
    this.accountNo = accountNo;
    this.storageService.setLocalStorage('accountNo', this.accountNo);    
  }

  getAccountNo() {
    this.accountNo = this.storageService.getLocalStorage('accountNo') || '';
    return this.accountNo;
  }

  setAccountDetails(value: any) {
    this.accountsDetails = value;
    this.storageService.setLocalStorage('accountDetail', JSON.stringify(this.accountsDetails));
    this.setArrearData();
    this.setAgreementData();
    this.setSpeedData();
    this.setContractData();
    this.setNextPaymentData();
    this.setAdditionalInfoData();
    this.accountSummarySubject.next(this.accountsDetails);
  }

  setArrearData() {
    this.myAccount.arrearData['arrearAmount'] = this.accountsDetails.arrears_plus_fees.toString();
    this.myAccount.arrearData.isArrangement = this.accountsDetails.is_arrangement;
    this.myAccount.arrearData.inArrears = this.accountsDetails.in_arrears;
    this.setShowArrear(this.accountsDetails.in_arrears);
  }

  getArrearAmount() {
    return this.accountsDetails.arrears_plus_fees.toString();
  }

  hasArrearAmount() {
    return this.accountsDetails.arrears_plus_fees?.toString() && this.accountsDetails.arrears_plus_fees.length && this.accountsDetails.in_arrears;
  }

  setShowArrear(value: boolean) {
    this.arrearShow = !value;
  }

  getShowArrear() {
    return this.arrearShow;
  }

  setAgreementData() {
    this.myAccount.myAgreementData['agreementType'] = this.accountsDetails.scheme_description;
    this.myAccount.myAgreementData['vehicleType'] = this.accountsDetails.goods_description;
    this.myAccount.myAgreementData['agreementNumber'] = this.accountsDetails.account_number;
    this.myAccount.myAgreementData['registrationNo'] = this.accountsDetails.registration_no;
  }

  setSpeedData() {
    let cbwithoutcomman = this.accountsDetails.current_balance.replaceAll(",", "");
    let amtLeft = cbwithoutcomman;
    let totalAmt = amtLeft.substring(1);
    let fpawithoutcomma = this.accountsDetails.final_payment_amount.replaceAll(",", "");
    let finalAmtTemp = isNotEmpOrNullOrUndef(fpawithoutcomma) ? fpawithoutcomma : '0';
    let finalAmt = finalAmtTemp.length > 0 ? finalAmtTemp.substring(1) : '0';
    let lastAmtTemp = this.accountsDetails.last_payment_amount;
    let lastAmt = lastAmtTemp.substring(1);
    let cawithoutcomma = this.accountsDetails.current_advance.replaceAll(",", "");
    let ccwithoutcomma = this.accountsDetails.current_charges.replaceAll(",", "");
    let amountleft = parseInt(totalAmt) - parseInt(fpawithoutcomma.substring(1));
    let amtPaid: any = ((parseInt(cawithoutcomma.substring(1)) + parseInt(ccwithoutcomma.substring(1))) - parseInt(cbwithoutcomman.substring(1)));

    if (amtPaid < 0) {
      amtPaid = amtPaid.toString().substring(1);
    }
    else if (amtPaid > 0) {
      amtPaid = amtPaid.toString();
    }
    else {
      amtPaid = "0";
    }
    this.myAccount.speedometerData['paidSoFar'] = amtPaid;
    this.myAccount.speedometerData['amountleft'] = amountleft.toString();
    this.myAccount.speedometerData['optionaFinalPayment'] = finalAmt;
    this.myAccount.speedometerData['remainingAmount'] = totalAmt;
    this.myAccount.speedometerData['lastPaymentAmount'] = lastAmt;
    this.myAccount.speedometerData['dateReceived'] = this.accountsDetails.last_payment_date;
  }

  setContractData() {
    this.myAccount.contractData['paymentLeft'] = this.accountsDetails.num_payments_left + ' of ' + this.accountsDetails.original_term;
    this.myAccount.contractData['originalTerm'] = this.accountsDetails.original_term;
  }

  setNextPaymentData() {
    let nextAmt = this.accountsDetails.next_payment_amount;
    this.myAccount.nextPaymentData['next_payment_amount'] = nextAmt;
    this.myAccount.nextPaymentData['next_payment_date'] = this.accountsDetails.next_payment_date;
  }

  setAdditionalInfoData() {
    this.myAccount.additionalInfoData['startDate'] = this.accountsDetails.inception_date;
    this.myAccount.additionalInfoData['apr'] = this.accountsDetails.apr;
    this.myAccount.additionalInfoData['annualMileage'] = this.accountsDetails.max_annual_mileage;
    let finalAmtTemp = isNotEmpOrNullOrUndef(this.accountsDetails.final_payment_amount) ? this.accountsDetails.final_payment_amount : '0';
    let finalAmt = (finalAmtTemp.length > 0) && (finalAmtTemp.indexOf("£") !== -1) ? finalAmtTemp.substring(1) : '0';
    this.myAccount.additionalInfoData['optionaFinalPayment'] = finalAmt;
    this.myAccount.additionalInfoData['optionFee'] = this.accountsDetails.option_fee;
    this.myAccount.additionalInfoData['finalPayment'] = this.accountsDetails.final_payment_date;
  }

  getMyAccount() {
    return this.myAccount;
  }

  getAccountDetails() {
    return this.accountsDetails;
  }

  setToken(value: string) {
    this.token = value;
    sessionStorage.setItem('tokenReq', this.token);
  }

  getToken() {
    return this.token;
  }

  setLoginAttempt(value: number) {
    this.loginAttempt = value;
  }

  getLoginAttempt() {
    return this.loginAttempt;
  }

  setUserName(value: string) {
    this.userName = value;
  }

  getUserName() {
    return this.userName;
  }

  setCustomerLogin(value: any) {
    this.customerLogin = value;
    sessionStorage.setItem("loginResponse", JSON.stringify(this.customerLogin));
    this.setCustomerId(this.customerLogin.customer_id);
  }

  getCustomerLogin() {
    this.customerLogin = sessionStorage.getItem("loginResponse") || '{}';
    return JSON.parse(this.customerLogin);
  }

  setTransactions(transactions: Transaction[]) {
    this.transactions = transactions;
  }

  getTransactions() {
    return this.transactions;
  }

  setDirectDebitResponse(data: DirectDebitResponse) {
    this.directDebitResponse = data;
  }

  getDirectDebitResponse() {
    return this.directDebitResponse;
  }

  setQuestionIIQ(data: QuestionIIQ) {
    this.detailsIIQ = data;
    this.setUserId(this.detailsIIQ.user_id);
    this.setStatusIIQ(this.detailsIIQ.status);
    this.setPasscodeIIQ(this.detailsIIQ.registration.passcode);
    this.setTransactionIdIIQ(this.detailsIIQ.registration.transaction_id);
    this.setQuestionAsked(this.detailsIIQ.registration.questions_asked);
    this.setQuestionsCorrect(this.detailsIIQ.registration.questions_correct);
    this.setQuestionList(this.detailsIIQ.registration.questions);
  }

  getQuestionIIQ() {
    return this.detailsIIQ;
  }

  setTransactionIdIIQ(value: string) {
    this.transactionIdIIQ = value;
  }

  getTransactionIdIIQ() {
    return this.transactionIdIIQ;
  }

  setQuestionAsked(value: number) {
    this.questionAsked = value;
  }

  getQuestionAsked() {
    return this.questionAsked;
  }

  setQuestionsCorrect(value: number) {
    this.questionsCorrect = value;
  }

  getQuestionsCorrect() {
    return this.questionsCorrect;
  }

  setQuestionList(value: any[]) {
    this.questionList = value;
  }

  getQuestionList() {
    return this.questionList;
  }

  setStatusIIQ(value: string) {
    this.statusIIQ = value;
  }

  getStatusIIQ() {
    return this.statusIIQ;
  }

  setPasscodeIIQ(value: string) {
    this.passcodeIIQ = value;
  }

  getPasscodeIIQ() {
    return this.passcodeIIQ;
  }

  setVerifyType(value: string) {
    this.verifyType = value;
  }

  getVerifyType() {
    return this.verifyType;
  }

  setFwdPwdData(data: any) {
    this.fwdPwdDetails = data;
    this.setFwdPwdLink(this.fwdPwdDetails.password_reset_link);
  }

  setFwdPwdLink(value: string) {
    this.fwdPwdLink = value;
  }

  getFwdPwdLink() {
    return this.fwdPwdLink;
  }

  setFwdPwdToken(value: string) {
    this.fwdPwdToken = value;
  }

  getFwdPwdToken() {
    return this.fwdPwdToken;
  }

  setValidResetLink(data: any) {
    this.resetLinkData = data;
    this.setVdResetLink(this.resetLinkData.status)
  }

  setVdResetLink(value: string) {
    this.validResetLink = value;
  }

  getVdResetLink() {
    return this.validResetLink;
  }

  setConfirmPwdData(data: any) {
    this.confirmPwdData = data;
    this.setConfirmPwdStatus(this.confirmPwdData.status);
  }

  setConfirmPwdStatus(value: string) {
    this.confirmPwdStatus = value;
  }

  getConfirmPwdStatus() {
    return this.confirmPwdStatus;
  }

  setPaymentDetails(value: any) {
    this.allPaymentCardDetail = value;
    this.paymentDetails = value;
  }

  getPaymentDetails() {
    return this.paymentDetails;
  }

  getAllPaymnetCardDetail() {
    return this.allPaymentCardDetail;
  }

  getPaymnetCardFirstDetail() {
    this.paymentCardFirstDetail = this.paymentDetails[0];
    return this.paymentCardFirstDetail;
  }

  getPaymnetCardRestDetail() {
    this.paymentCardRestDetail = this.paymentDetails.splice(1, this.allPaymentCardDetail.length - 1);
    return this.paymentCardRestDetail;
  }

  setCardPaymentDetails(value: any) {
    this.setPostDataUrl(value.payment_detail.checkout_url);
    this.cardPaymentDetails = value.payment_detail;
    this.setPaymentId(this.cardPaymentDetails.transaction_id);
    localStorage.setItem('cardPaymentsResponse', JSON.stringify(this.cardPaymentDetails));
  }

  getCardPaymentDetails() {
    return this.cardPaymentDetails;
  }

  setPostDataUrl(value: any) {
    this.postUrlData = value;
  }

  getPostDataUrl() {
    return this.postUrlData;
  }

  setPaymentId(paymentId: string) {
    this.paymentId = paymentId;
    localStorage.setItem('paymentId', this.paymentId);
  }

  getPaymentId() {
    return this.paymentId;
  }

  setPaymentRequestPayLoad(paymentPayLoad: any) {
    this.paymentPayLoad = paymentPayLoad;
    return this.paymentPayLoad;
  }

  getPaymentRequestPayLoad() {
    return this.paymentPayLoad;
  }

  setSettlementDetails(settlementDetails: SettlementResponse) {
    this.settlementDetails = settlementDetails;
  }

  getSettlementDetails() {
    return this.settlementDetails;
  }
  setpaymentErrorDetails(value: any) {
    this.cardPaymentErrorDetails = value;
  }

  getpaymentErrorDetails() {
    return this.cardPaymentErrorDetails;
  }

  setCardPaymentUpdateData(value: any) {
    this.cardPaymentUpdateDetails = value;
  }

  getCardPaymentUpdateData() {
    return this.cardPaymentUpdateDetails;
  }

  setRecipientMobileNumber(mobileNumber: string) {
    let filterMobileNumber;
    if (mobileNumber.length === 11) {
      filterMobileNumber = mobileNumber.slice(1);
    } else {
      filterMobileNumber = mobileNumber;
    }
    let validMobileNumber = 44 + filterMobileNumber;
    this.recipientMobileNumber = validMobileNumber;
  }

  getRecipientMobileNumber() {
    return this.recipientMobileNumber;
  }

  setSettlementResponse(settlementDetails: Settlement) {
    this.settlementResponse = settlementDetails;
  }

  getSettlementResponse() {
    return this.settlementResponse;
  }

  getDueDate() {
    return this.dueDate;
  }

  setDueDate(dueDate: DueDate) {
    this.dueDate = dueDate;
  }

  setOverpaymentDetails(overpaymentDetails: any) {
    this.overpaymentDetails = overpaymentDetails;
  }

  getOverpaymentDetails() {
    return this.overpaymentDetails;
  }

  setSettlementAmount(settlementAmount: string) {
    this.settlementAmount = settlementAmount;
  }

  getSettlementAmount() {
    return this.settlementAmount;
  }

  setFailCountValue(value: any) {
    sessionStorage.setItem('failCount', value);
    this.failCount = value;
  }

  getFailCountValue() {
    this.failCount = sessionStorage.getItem('failCount') || 0;
    return parseInt(this.failCount);
  }

  setErrorServer() {
    this.bannerMessage.closeIconClass = 'clear';
    this.bannerMessage.infoTypeClass = 'error-icon';
    this.bannerMessage.leftSectionColorClass = 'message-failure';
    this.bannerMessage.titleText = 'We\'re sorry, something has gone wrong.';
    this.bannerMessage.isBannerClosed = false;
    this.messageService.setBannerMessage(this.bannerMessage);
  }

  setErrorScreen(val: string) {
    this.bannerMessage.closeIconClass = 'clear';
    this.bannerMessage.infoTypeClass = 'error-icon';
    this.bannerMessage.leftSectionColorClass = 'message-failure';
    this.bannerMessage.titleText = val;
    this.bannerMessage.isBannerClosed = false;
    this.messageService.setBannerMessage(this.bannerMessage);
  }

  setMsgScreen(val: string, icon: string, msgtype: string) {
    this.bannerMessage.closeIconClass = 'clear';
    this.bannerMessage.infoTypeClass = icon;
    this.bannerMessage.leftSectionColorClass = msgtype;
    this.bannerMessage.titleText = val;
    this.bannerMessage.isBannerClosed = false;
    this.messageService.setBannerMessage(this.bannerMessage);
  }

  sessionLogout() {
    this.sessionLogin();
    sessionStorage.setItem('previousPageUrl', appConfig.welcomeURL);
    location.href = appConfig.loginURL;
  }

  sessionLogin() {
    localStorage.removeItem('loggedInEmail');
    localStorage.removeItem('customerDetail');
    localStorage.removeItem('customerId');
    localStorage.removeItem('accountNo');
    localStorage.removeItem('accountDetail');
    localStorage.removeItem('paymentId');
    localStorage.removeItem('redirectionFlag');
    localStorage.removeItem('settlementAmount');
    localStorage.removeItem('paymentUpdateDetails');
    localStorage.removeItem('paymentErrorDetails');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('settlementRedirectionFlag');
    localStorage.removeItem('makePaymentFAQsRedirection');
    localStorage.removeItem('getInTouchRedirection');
    localStorage.removeItem('proposalDetail');
    localStorage.removeItem('gfvDetails');
    this.BalloonRefinanceQuoteData = {
      balloonRefinance: 0,
      balloonPayment: 0,
      monthlyPayment: 0,
      termLength: 0, 
      optionalFinalPayment: 0,
      totalInterest: 0,
      totalAmount: 0
    }
    this.setQuoteData(this.BalloonRefinanceQuoteData);
    localStorage.removeItem('changedAddressDetail');
    localStorage.removeItem('paymentStatus');
    localStorage.removeItem('cardPaymentsResponse');
    localStorage.removeItem('settlementExpiryDate');
    sessionStorage.clear();
  }

  sessionInvalid() {
    if (RuntimeEnv.backbase) {
      if (!this.pageConfig.designmode) {
        if(!this.authService.isloggedIn()) {
          location.href = appConfig.loginURL;
        }
      }
    }
  }

  setBureauCallAction(action: string) {
    sessionStorage.setItem("bureauCTA", action);
    this.bureauCallAction = action;
  }

  getBureauCallAction() {
    this.bureauCallAction = sessionStorage.getItem("bureauCTA") || '';
    return this.bureauCallAction;
  }

  updateBureauCallFailCount(count: any) {
    sessionStorage.setItem("bureauCallFailCount", count);
    this.bureauCallFailCount = count;
  }

  getBureauCallFailCount() {
    this.bureauCallFailCount = sessionStorage.getItem("bureauCallFailCount") || 0;
    return parseInt(this.bureauCallFailCount);
  }

  setSelectedTermExtensionQuote(quote: any) {
    sessionStorage.setItem("selectedQuote", JSON.stringify(quote));
    this.selectedTermExtensionQuote = quote;
  }

  getSelectedTermExtensionQuote() {
    this.selectedTermExtensionQuote = sessionStorage.getItem("selectedQuote") || {};
    return this.selectedTermExtensionQuote;
  }

  setRequestedRental(rental: string) {
    sessionStorage.setItem("requestedRental", rental);
    this.requestedRental = rental;
  }

  getRequestedRental() {
    this.requestedRental = sessionStorage.getItem("requestedRental") || "";
    return this.requestedRental;
  }

  setRequestedTerm(term: string) {
    sessionStorage.setItem("term", term);
    this.requestedTerm = term;
  }

  getRequestedTerm() {
    this.requestedTerm = sessionStorage.getItem("term") || "";
    return this.requestedTerm;
  }

  setTermExtFormDetail(formDetail: any) {
    sessionStorage.setItem("termExtForm", JSON.stringify(formDetail));
    this.termExtFormDetail = formDetail;
  }

  getTermExtFormDetail() {
    this.termExtFormDetail = sessionStorage.getItem("termExtForm");
    if (this.termExtFormDetail) {
      this.termExtFormDetail = JSON.parse(this.termExtFormDetail);
    }
    return this.termExtFormDetail;
  }

  setStepperStep(currentStep: number) {
    sessionStorage.setItem("currentStep", currentStep.toString());
    this.stepperStep = currentStep;
  }

  getStepperStep() {
    let currentStep: any = sessionStorage.getItem("currentStep") || 0;
    return parseInt(currentStep);
  }

  setPwdReset(value: boolean) {
    this.pwdReset = value;
  }

  getPwdReset() {
    return this.pwdReset;
  }
  setPaymentSuccessReciptNumber(reciptNumber: any) {
    this.paymentReciptNumber = reciptNumber;
    return this.paymentReciptNumber;
  }

  getPaymentSuccessReciptNumber() {
    return this.paymentReciptNumber;
  }

  setLoggedInEmail(email: any){
    this.loggedInEmail = email;
    this.storageService.setLocalStorage('loggedInEmail', email);
  }

  getLoggedInEmail(){
    this.loggedInEmail = this.storageService.getLocalStorage('loggedInEmail') || '';
    return this.loggedInEmail;
  }

  setPreviousUrl(previousUrl: any){
    this.previousPageUrl = previousUrl;
    sessionStorage.setItem('previousPageUrl', previousUrl);
  }

  getPreviousUrl(){
    return this.previousPageUrl;
  }

  setMailboxMsg(data: InboxResponse) {
    this.mailboxMsg = data;
    this.setMailboxMsgList(data.document_list);
    this.setMailboxUnreadCount(data.unread_documents_count);
  }

  setMailboxMsgList(msgList: InboxMsgList[]) {
    this.mailboxMsgList = msgList;
  }

  getMailboxMsgList() {
    return this.mailboxMsgList;
  }

  setMailboxUnreadCount(msgCount: number) {
    this.mailboxUnreadCount.next(msgCount);
  }

  getMailboxUnreadCount(): Observable<number> {
    return this.mailboxUnreadCount.asObservable();
  }

  setDealersDetails(dealersData: any) {
    this.dealersDeatails = dealersData;
  }

  getDealersDetails() {
    return this.dealersDeatails;
  }

  /* setOnOffSwitchValues(onOffObject: Object) {
    this.onOffObject = JSON.stringify(onOffObject);
    localStorage.setItem("onOffFlag", this.onOffObject);
  }

  getOnOffSwitchValues() {
    return localStorage.getItem("onOffFlag");
  } */

  setBudgetDetail(budgetDetail:BudgetPlanner) {
    this.budgetDetail = budgetDetail
  }

  setSubmitBudgetDetail(detail:any) {
    this.submitBudgetDetail = detail
  }

  getSubmitBudgetDetail() {
    this.submitBudgetDetail = Object.keys(this.submitBudgetDetail).length > 0  ?  this.submitBudgetDetail : {};
    return this.submitBudgetDetail
  }


  getBudgetDetail() {
    this.budgetDetail = Object.keys(this.budgetDetail).length > 0  ?  this.budgetDetail : {};
    return this.budgetDetail
  }

  getCurrentBudgetFormId() {
    if(this.budgetDetail.id) {
      return this.budgetDetail.id
    }
    else{
      return '';
    }
  }

  get getCurrentPageBudgetForm(){
    return this.budgetDetail && !isNaN(parseInt(this.budgetDetail.current_page))
    ? parseInt(this.budgetDetail.current_page)-1
    : -1;
  }

  get getBudgetFormStatus(){
    return this.budgetDetail.status || '';
  }


  deleteBudgetDetail() {
    this.budgetDetail = {};
  }

  setBudgetSummaryData(budgetSummaryData:any): void {
    this.budgetSummaryData = budgetSummaryData
  }

  getBudgetSummaryData() {
    return this.budgetSummaryData
  }

  deleteSubmitBudgetDetail() {
    this.submitBudgetDetail = {};
  }

  set bugdgetStatus(status:string){
    this.budgetDetail.status = status
  }

  setArrearsBannerRoute(isTrue:any) {
    this.isRouteFromArrearsBanner = isTrue
  }

  getArrearsBannerRoute() {
    return this.isRouteFromArrearsBanner
  }

  setEocRoute(routePage:any) {
    this.routePageName = routePage;
  }

  getEocRoute() {
    return this.routePageName;
  }

  getCurrentEmail(): Observable<string> {
    return Observable.of(this.loggedInEmail);
  }

  setAddressChangeNotification(val: boolean) {
    this.addressChanged = val;
  }

  getAddressChangeNotification() {
    return this.addressChanged;
  }
  setContactPreferenceData(data:any) {
    this.contactPreferenceData = data;
    sessionStorage.setItem("contactPreference", JSON.stringify(this.contactPreferenceData));
  }

  getContactPreferenceData() {
    this.contactPreferenceData = sessionStorage.getItem("contactPreference") || '{}';
    return JSON.parse(this.contactPreferenceData);
  }
  
  getContactPreferenceBannerShown(){
    let data:any = sessionStorage.getItem("contactPreferenceBanner") ?? false;
    return JSON.parse(data)   
  }

  setContactPreferenceBannerShown(isTrue: any) {
    sessionStorage.setItem("contactPreferenceBanner", isTrue);
  }

  getContactPreferencePopupShown(){
    let data:any = sessionStorage.getItem("contactPreferencePopup");
    return JSON.parse(data)   
  }

  setContactPreferencePopupShown(isTrue: any) {
    sessionStorage.setItem("contactPreferencePopup", isTrue);
  }

  setIsContactPreferenceFeature(isFeatureEnabled: any) {
   this.isContactPreferencesFeature = sessionStorage.setItem("isContactPreferencesFeature", isFeatureEnabled);
  }

  getIsContactPreferenceFeature()  {
    this.isContactPreferencesFeature = sessionStorage.getItem("isContactPreferencesFeature") ?? false;
    return JSON.parse(this.isContactPreferencesFeature);
  }

  setIsContactPreferenceRegisterFeature(isFeatureEnabled: any) {
   this.isContactPreferencesRegisterFeature = sessionStorage.setItem("isContactPreferencesRegisterFeature", isFeatureEnabled );
  }

  getIsContactPreferenceRegisterFeature()  {
    this.isContactPreferencesRegisterFeature = sessionStorage.getItem("isContactPreferencesRegisterFeature") ?? false;
    return JSON.parse(this.isContactPreferencesRegisterFeature);
  }

  setComplaintsFeature(isFeatureEnabled: any) {
   this.isComplaintFeatureEnabled = sessionStorage.setItem("isComplaintFeatureEnabled", isFeatureEnabled);
  }

  getComplaintsFeature()  {
    this.isComplaintFeatureEnabled = sessionStorage.getItem("isComplaintFeatureEnabled") ?? false;
    return JSON.parse(this.isComplaintFeatureEnabled);
  }

  setQuoteData(detail:BalloonRefinanceSavedQuote) {
    this.BalloonRefinanceQuoteData = detail;
  }

  getQuoteData() {    
    return this.BalloonRefinanceQuoteData;
  }

  setBRCurrentPage(currentPage: number) {
    this.brCurrentPageSubject.next(currentPage);
  }

  setBrReturnJourney(flag: boolean) {
    this.brReturnJourneySubject.next(flag)
  }

  setBrHideStepper(flag: boolean) {
    this.brgHideStepperSubject.next(flag);
  }

  setBrgDeclinePage(flag: boolean) {
    this.brgDeclinePageSubject.next(flag)
  }

  getFormattedDate(dateInddMMYYYY: string) {
    let dateAfterDilimiter = dateInddMMYYYY && dateInddMMYYYY.split("/");
    return dateAfterDilimiter[1] + "/" + dateAfterDilimiter[0] + "/" + dateAfterDilimiter[2];
  }

  getLastDateForEocDecision(completionDate: string) {
    let noOfDays: number = 14;
    let formattedCompletionDate = new Date(this.getFormattedDate(completionDate));
    let lastDayForDecision = new Date(formattedCompletionDate);
    lastDayForDecision.setDate(formattedCompletionDate.getDate() - noOfDays)
    return new Date(lastDayForDecision)
  }

  getLastDateForSigningAgreement(completionDate: string) {
    let noOfDays: number = 6;
    let formattedCompletionDate = new Date(this.getFormattedDate(completionDate));
    let lastDayForDecision = new Date(formattedCompletionDate);
    lastDayForDecision.setDate(formattedCompletionDate.getDate() - noOfDays)
    return new Date(lastDayForDecision)
  }

  setProposalEditMode(editMode: boolean) {
    this.proposalEditMode = editMode;
  }

  getProposalEditMode() {
    return this.proposalEditMode;
  }

  setProposalCustomerDetails(data: any) {
    this.proposalCustomerDetails = data;
  }

  getProposalCustomerDetails() {
    return this.proposalCustomerDetails;
  }

  setDecisionData(data: any) {
    this.decisionDetails = data;
  }

  getDecisionData() {
    return this.decisionDetails;
  }

  getAddressData() {
    return this.addressData;
  }

  setProposalCustomerPayload(data: any) {
    this.proposalCustomerPayload = data;
  }

  getProposalCustomerPayload() {
    return this.proposalCustomerPayload;
  }

  setAddressDetails(addressData: addressList[]) {
    this.addressPost = addressData;
  }

  getAddressDetails() {
    return this.addressPost;
  }

  setisEocBrResumeAppBannerClosed(flag: boolean) {
    this.isEocBrResumeAppBannerClosed = flag;
  }

  getisEocBrResumeAppBannerClosed(): boolean {
    return this.isEocBrResumeAppBannerClosed;
  }

  setIsEocLandingPageResumeBrAppBannerClosed(flag: boolean) {
    this.isEocLandingPageResumeBrAppBannerClosed = flag;
  }

  getIsEocLandingPageResumeBrAppBannerClosed(): boolean {
    return this.isEocLandingPageResumeBrAppBannerClosed;
  }

  setIsBrLandingPageResumeBrAppBannerClosed(flag: boolean) {
    this.isBrLandingPageResumeBrAppBannerClosed = flag;
  }

  getIsBrLandingPageResumeBrAppBannerClosed(): boolean {
    return this.isBrLandingPageResumeBrAppBannerClosed;
  }

  setIsReturnJourneyBrQuotePage(flag: boolean) {
    this.isReturnJourneyBrQuotePage = flag;
  }

  getIsReturnJourneyBrQuotePage(): boolean {
    return this.isReturnJourneyBrQuotePage;
  }
  
  setUnemployedStatus(int?: number ) {
    this.unemployedStatus = int;
  }

  getUnemployedStatus(): number | undefined {
    return this.unemployedStatus;
  }

  setCalculatedModagDetails(data: any) {
    this.calculatedModagDetails = data;
  }

  getCalculatedModagDetails(): any {
    return this.calculatedModagDetails;
  }

  setVehicleType(): any {
    let bikeFlag = appConfig.motorbikeGdsCodes.includes(this.accountsDetails?.goods_code);
    let carFlag = appConfig.carCodesForEoc.includes(this.accountsDetails?.goods_code);
    this.vehicleType = { bikeFlag, carFlag };
  }

  getVehicleType() {
    return this.vehicleType;
  }

  setCheckVehicleType () {
    this.vehicleTypeCheck = this.vehicleType.bikeFlag ? vehicleTypes.bike : vehicleTypes.car;
  }

  getCheckVehicleType () {
    return this.vehicleTypeCheck;
  }

  setErrorBrServer (val: boolean) {
    this.errorBrServer = val;
  }

  getErrorBrServer () {
    return this.errorBrServer;
  }

  setComplaintsFormData(complaintsFormData: any) {
    this.complaintsFormData = complaintsFormData;
  }

  getComplaintsFormData() {
    return this.complaintsFormData;
  }

  setComplaintsGetStartedContent(complaintContent: any) {
    this.complaintGetStartedContent = complaintContent;
  }

  getComplaintsGetStartedContent() {
    return this.complaintGetStartedContent;
  }
}
