import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ContentService } from '@backbase/universal-ang/content';
import { Observable } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ComplaintWidgetItem } from '@wss/model/complaint';
import { WSSAppConstant, patternMap } from '@wss/config/wss-app-constants';
import { RouterService } from '@backbase/foundation-ang/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { DatastoreService } from '@wss/service/datastore.service';
import { DataService } from '../../services/data.service';
import { MessageService } from '@wss/service/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@wss/complaints-widget/guard/deactivation.guard';
import { complaintCategoriesServicing, complaintCategoriesSQD, getComplaintsCanDeactivate } from '@wss/common/util/util';

@Component({
  selector: 'bb-complaint-relate-to',
  templateUrl: 'complaint-relate-to.component.html',
  providers: [ContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ComplaintRelateToComponent implements OnInit, CanComponentDeactivate {

  formId: string = "complaintRelateToSelectionForm";
  complaintRelateToSelectionForm: any;
  accountDetail: any;
  customerAddress: any = {};
  personalDetails: any = {};
  customerDetail: any = {};
  emailAdress: string = '';
  submitted: boolean = false;
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  complaintConcernType: string = '';
  textAreaOneRemainingCharacter: any = 0;
  patternMatch = /[a-zA-Z0-9!@#$€£%^&*()_+\-=\[\]{};`¬ ~':"\\|,.<>\/?]/g;
  invalidContactDetails: boolean = false;
  complaintCategoriesServicingLevelTwo: any;
  mySelections: any;
  selectedCategories: any;
  complaintWidgetItem: any;
  
  @HostListener("window:beforeunload")
  canDeactivate() {
    return getComplaintsCanDeactivate(this.formDataService.formData, this.modalService);
  }

  constructor(
    private fb: FormBuilder,
    private readonly bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    private router: RouterService,
    private datastoreService: DatastoreService,
    private formDataService: DataService,
    private messageService: MessageService,
    public modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.complaintConcernType = sessionStorage.getItem('ConcernType') as string;
    this.initConcernSelectionForm(this.complaintConcernType);
    this.accountDetail = this.datastoreService.getAccountDetails();
    this.customerDetail = this.datastoreService.getCustomerDetail();
    if (this.customerDetail) {
      this.customerAddress = this.customerDetail?.addresses?.address_detail;
      if (this.customerAddress?.post_town != null) {
        this.customerAddress.post_town = this.customerAddress?.post_town?.toLowerCase();
      }
      let firstName = this.customerDetail?.individual?.first_name;
      let surname = this.customerDetail?.individual?.surname;
      if (firstName?.length > 18) {
        firstName = firstName.substr(0, 18);
      }
      if (surname?.length > 40) {
        surname = surname.substr(0, 40);
      }
      this.personalDetails.fullname = firstName + ' ' + surname;
      this.personalDetails.fullname = this.personalDetails.fullname.toLowerCase();
    }
    this.emailAdress = this.datastoreService.getLoggedInEmail();
    this.textboxCharacterChange();
  }

  complaintWidgetItem$: Observable<ComplaintWidgetItem | undefined> = this.bbContentService.getContent<ComplaintWidgetItem>('complaintWidgetItem');


  ngOnInit(): void {
    this.messageService.setCurrentStep(1);
    this.tealiumService.view({
      JourneyName: 'Complaints Digitisation',
      JourneyStepName: 'Complaint relates to - ' + this.complaintConcernType,
      CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
    });
    const savedData = this.formDataService.getFormData(this.formId);
    if (Object.keys(savedData).length !== 0) {
      this.complaintRelateToSelectionForm.setValue(savedData);
    }
    this.complaintWidgetItem$.subscribe((pageItem: any) => {
      this.complaintWidgetItem = pageItem;
      this.complaintWidgetItem.complaintRelateTo.complaintCategoriesSQD = complaintCategoriesSQD(this.complaintWidgetItem.complaintRelateTo.complaintCategoriesSQD)
      this.complaintWidgetItem.complaintRelateTo.complaintCategoriesServicing = complaintCategoriesServicing(this.complaintWidgetItem.complaintRelateTo.complaintCategoriesServicing);
      this.changeDetectorRef.detectChanges();
    });
  }

  initConcernSelectionForm(concernType?: any) {
    this.complaintRelateToSelectionForm = this.fb.group({
      concernsRaiseWithDealerOption: concernType == 'Vehicle' ? ["", [Validators.required]] : [""],
      speakWithSomeElseOption: ["", [Validators.required]],
      complaintRelateToSelection: ["", [Validators.required]],
      selectedCategories: [""],
      whatWentWrongComplaintSelection: concernType == 'Vehicle' ? [""] : ["", [Validators.required]],
      contactDetail: [''],
    });
    this.changegroup();
  }

  textboxCharacterChange() {
    this.complaintRelateToSelectionForm.valueChanges.subscribe((value: any) => {
      this.textAreaOneRemainingCharacter = value.contactDetail.length;
      this.formDataService.saveFormData(this.formId, value);
    });

    this.complaintRelateToSelectionForm.controls['contactDetail'].valueChanges.forEach(
      (value: string) => {
        if (value && value.length > 0 && !patternMap.textboxPattern.test(value)) {
          this.invalidContactDetails = true;
        } else {
          this.invalidContactDetails = false;
        }
      }
    );
  }

  get speak_with_someone_else_selection_value() {
    return this.complaintRelateToSelectionForm.get('speakWithSomeElseOption');
  }

  get raise_concern_selection_value() {
    return this.complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption');
  }

  get complaintRelateToFormControl() {
    return this.complaintRelateToSelectionForm.controls;
  }

  changegroup() {
    this.complaintRelateToSelectionForm.get('speakWithSomeElseOption').valueChanges.subscribe((value: string) => {
      if (value == "Yes") {
        this.complaintRelateToSelectionForm.get('contactDetail').setValidators([Validators.required, Validators.pattern(patternMap.textboxPattern)]);
      } else {
        this.complaintRelateToSelectionForm.get('contactDetail').clearValidators();
      }
      this.complaintRelateToSelectionForm.get('contactDetail').updateValueAndValidity();
    });

    this.complaintRelateToSelectionForm.get('complaintRelateToSelection').valueChanges.subscribe((value: string) => {
      if (value != "") {
        this.complaintCategoriesServicingLevelTwo = this.complaintWidgetItem.complaintRelateTo.complaintCategoriesServicing[value]
      }
      if (this.complaintConcernType == 'FinanceAgreement') {
        this.complaintRelateToSelectionForm.get('whatWentWrongComplaintSelection').reset();
        this.complaintRelateToSelectionForm.get('whatWentWrongComplaintSelection').updateValueAndValidity();
      }
    });
  }

  handlePreviousBtn() {
    this.formDataService.saveFormData(this.formId, this.complaintRelateToSelectionForm.value);
    this.router.navigate(['get-started']);
  }

  handleNextBtn() {
    this.submitted = true;
    if (this.complaintRelateToSelectionForm.valid) {
      this.formDataService.saveFormData(this.formId, this.complaintRelateToSelectionForm.value);
      this.router.navigate(['tell-us']);
    }
  }

  openProfilePopup(event: any) {
    event.stopPropagation();
    this.messageService.setProfileActive(true);
  }

  returnZero() {
    return 0;
  }

  complaintCategoriesSelection() {
    if (this.complaintRelateToSelectionForm.get('complaintRelateToSelection').value.length < 6) {
      this.mySelections = this.complaintRelateToSelectionForm.get('complaintRelateToSelection').value;
      this.selectedCategories = this.mySelections?.join('>');
      this.complaintRelateToSelectionForm.get('selectedCategories').setValue(this.selectedCategories);
    } else {
      this.complaintRelateToSelectionForm.get('complaintRelateToSelection').setValue(this.mySelections);
    }
  }
}
