<div *ngIf="complaintWidgetItem">
  <form [formGroup]="complaintRelateToSelectionForm" formInvlidControlScroll>
    <div class="container-column">
      <div class="help-centre-title">
        <span *ngIf="complaintConcernType == 'Vehicle' || ''" class="section-label-title"
          test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintRelateTo.pageTitle}}
        </span>
        <span *ngIf="complaintConcernType == 'FinanceAgreement'" class="section-label-title"
          test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintRelateTo.financeAgreementPageTitle}}
        </span>
      </div>

      <div class="mt-20px">
        <bb-content-html [html]="complaintWidgetItem.complaintRelateTo.pageHeaderBelowText"></bb-content-html>
      </div>

      <div class="mt-20px">
        <bb-info-alert [infoContent]="complaintWidgetItem.complaintRelateTo.shareDetailWithDealerInfo"></bb-info-alert>
      </div>

      <div class="change-profile-text para-inline">
        <bb-content-html [html]="complaintWidgetItem.complaintRelateTo.changeProfileText"></bb-content-html>
        <span *ngIf="complaintWidgetItem.complaintRelateTo.profileLinkText">
          <a (click)="openProfilePopup($event)" title="{{complaintWidgetItem.complaintRelateTo.profileLinkText}}"
            test_id="profileLinkText" id="profileLinkText">{{ complaintWidgetItem.complaintRelateTo.profileLinkText
            }}</a>.</span>
      </div>

      <div class="mt-20px">
        <h2><strong id="desc">{{complaintWidgetItem.complaintRelateTo.yourDetailHeaderText}}</strong></h2>
      </div>

      <div class="user-details-wrapper mt-10px">
        <table aria-describedby="desc">
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.yourNameLabel}}</th>
            <td class="caps-title">{{personalDetails?.fullname}}</td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.addressLabel}}</th>
            <td>
              <div class="caps-title">
                <span
                  *ngIf="(customerAddress?.flat_number != null && customerAddress?.flat_number &&  customerAddress?.flat_number?.length > 0 && customerAddress?.flat_number != ' ')">
                  {{customerAddress.flat_number}}</span>
                <span
                  *ngIf="(customerAddress?.house_name != null && customerAddress.house_name &&  customerAddress?.house_name.length > 0 && customerAddress?.house_name != ' ')">
                  {{customerAddress.house_name | lowercase}},</span>
              </div>
              <div class="caps-title"
                *ngIf="(customerAddress?.street_name != null && customerAddress?.street_name &&  customerAddress?.street_name?.length > 0 && customerAddress?.street_name != '  ')">
                {{customerAddress.street_name | lowercase}},</div>
              <div>
                <span class="caps-title"
                  *ngIf="(customerAddress?.post_town != null && customerAddress?.post_town &&  customerAddress?.post_town?.length > 0 && customerAddress?.post_town != ' ')">
                  {{customerAddress.post_town}},
                </span>
                <span class="caps-title" *ngIf="customerAddress?.county">{{customerAddress.county | lowercase}}, </span>
                <span *ngIf="customerAddress?.post_code?.length > 0">{{customerAddress.post_code}}</span>
              </div>
            </td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.mobileLabel}}</th>
            <td>{{customerDetail.contact_details.mobile_phone_number ?
              customerDetail.contact_details.mobile_phone_number : '-'}}</td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.telephoneLabel}}</th>
            <td>{{customerDetail.contact_details.home_phone_number ? customerDetail.contact_details.home_phone_number
              : '-'}}</td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.telephoneWorkLabel}}</th>
            <td>{{customerDetail.contact_details.work_phone_number ? customerDetail.contact_details.work_phone_number
              : '-'}}</td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.emailLabel}}</th>
            <td>{{emailAdress}}</td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.agreementNumberLabel}}</th>
            <td data-cs-mask>{{accountDetail.account_number}}</td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.vehicalRegistrationLabel}}</th>
            <td data-cs-mask>{{accountDetail.registration_no}}</td>
          </tr>
          <tr>
            <th>{{complaintWidgetItem.complaintRelateTo.goodDescriptionLabel}}</th>
            <td class="caps-title">{{accountDetail.goods_description | lowercase}}</td>
          </tr>
        </table>
      </div>

      <div class="form-group mt-25px mb-0" *ngIf="complaintConcernType == 'Vehicle' || ''">
        <div test_id="concernsRaiseWithDealerTextLabel">
          <strong>{{complaintWidgetItem.complaintRelateTo.concernsRaiseWithDealerText}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="concernsRaiseWithDealerOption"
              id="concernsRaiseWithDealerYesSelection"
              [ngClass]="{'has-Error-Radio': submitted && complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption').errors && complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption').invalid && complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption').errors.required}"
              value="Yes"><span class="label"
              test_id="concernsRaiseWithDealerYesSelection">{{complaintWidgetItem.complaintRelateTo.yesText}}</span>
          </label>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="concernsRaiseWithDealerOption" value="No"
              id="concernsRaiseWithDealerNoSelection"
              [ngClass]="{'has-Error-Radio': submitted && complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption').errors && complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption').invalid && complaintRelateToSelectionForm.get('concernsRaiseWithDealerOption').errors.required}"><span
              class="label"
              test_id="concernsRaiseWithDealerNoSelection">{{complaintWidgetItem.complaintRelateTo.noText}}</span>
          </label>
        </div>
        <div *ngIf="submitted && complaintRelateToFormControl.concernsRaiseWithDealerOption.errors?.required">
          <small class="text-danger" test_id="concernsRaiseWithDealerSelectionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="dealer-info-alert mt-10px" *ngIf="raise_concern_selection_value?.value == 'No'">
        <bb-warning-alert [warningContent]="complaintWidgetItem.complaintRelateTo.warningTextInfo"></bb-warning-alert>
      </div>

      <div class="form-group mt-30px">
        <div test_id="speakWithSomeElseInfoLabel">
          <strong>{{complaintWidgetItem.complaintRelateTo.speakWithSomeElseLabel}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="speakWithSomeElseOption"
              id="speakOnYourBehalfYesOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintRelateToSelectionForm.get('speakWithSomeElseOption').errors && complaintRelateToSelectionForm.get('speakWithSomeElseOption').invalid && complaintRelateToSelectionForm.get('speakWithSomeElseOption').errors.required}"
              value="Yes"><span class="label"
              test_id="speakOnYourBehalfYesOption">{{complaintWidgetItem.complaintRelateTo.yesText}}</span>
          </label>
        </div>
        <div class="form-group form-group-textarea mt-10px ps-30"
          *ngIf="speak_with_someone_else_selection_value?.value == 'Yes'">
          <bb-content-html [html]="complaintWidgetItem.complaintRelateTo.alternativeConcatPersonInfo"></bb-content-html>
          <div class="mb-10px">
            <span class="mb-10px">{{200-textAreaOneRemainingCharacter}}
              {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
          </div>
          <textarea
            [ngClass]="{'has-Error': submitted && complaintRelateToSelectionForm.get('contactDetail').errors && (complaintRelateToSelectionForm.get('contactDetail').invalid || complaintRelateToSelectionForm.get('contactDetail').errors.required)}"
            class="form-control" id="alternativeConcatPersonInfoTextarea" formControlName="contactDetail" rows="3"
            maxlength="200"></textarea>
          <div *ngIf="submitted && complaintRelateToFormControl.contactDetail.errors?.required">
            <small class="text-danger" test_id="contactDetail">
              {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
            </small>
          </div>
          <div *ngIf="invalidContactDetails">
            <small class="text-danger" test_id="contactDetail">
              {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
            </small>
          </div>

        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="speakWithSomeElseOption"
              id="speakOnYourBehalfNoOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintRelateToSelectionForm.get('speakWithSomeElseOption').errors && complaintRelateToSelectionForm.get('speakWithSomeElseOption').invalid && complaintRelateToSelectionForm.get('speakWithSomeElseOption').errors.required}"
              value="No"><span class="label"
              test_id="speakOnYourBehalfNoOption">{{complaintWidgetItem.complaintRelateTo.noText}}</span>
          </label>
        </div>
        <div *ngIf="submitted && complaintRelateToFormControl.speakWithSomeElseOption.errors?.required">
          <small class="text-danger" test_id="speakWithSomeElseOptionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>


      <div class="form-group mt-10px mb-0">
        <label>{{complaintWidgetItem.complaintRelateTo.complaintRelateToDropdownLable}}</label>
        <div class="select-wrapper" *ngIf="complaintConcernType == 'Vehicle'">
          <div class="sub-text mb-10px" test_id="complaintRelateToDropdownSubText">
            {{ complaintWidgetItem.complaintRelateTo.complaintRelateToDropdownSubText }}
          </div>
          <div class="select-container">
            <mat-form-field>
              <mat-select test_id="complaintRelateToDropdown" formControlName="complaintRelateToSelection"
                id="complaintRelateToDropdownSelection" class="form-control" disableOptionCentering panelClass="myPanelClass"
                [ngClass]="{'has-Error': submitted && complaintRelateToSelectionForm.get('complaintRelateToSelection').errors && complaintRelateToSelectionForm.get('complaintRelateToSelection').invalid && complaintRelateToSelectionForm.get('complaintRelateToSelection').errors.required}"
                multiple (selectionChange)="complaintCategoriesSelection()">
                <mat-option *ngFor="let complaintCategories of complaintWidgetItem.complaintRelateTo.complaintCategoriesSQD" 
                  [value]="complaintCategories">{{complaintCategories}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="select-wrapper" *ngIf="complaintConcernType == 'FinanceAgreement'">
          <div class="select-container">
            <mat-form-field>
              <mat-select test_id="complaintRelateToDropdown" formControlName="complaintRelateToSelection"
                [ngClass]="{'has-Error': submitted && complaintRelateToSelectionForm.get('complaintRelateToSelection').errors && complaintRelateToSelectionForm.get('complaintRelateToSelection').invalid && complaintRelateToSelectionForm.get('complaintRelateToSelection').errors.required}"
                id="complaintRelateToDropdownSelection" class="form-control" disableOptionCentering panelClass="myPanelClass">
                <mat-option [value]="null" disabled></mat-option>
                <mat-option *ngFor="let item of complaintWidgetItem.complaintRelateTo.complaintCategoriesServicing | keyvalue: returnZero" [value]="item.key">{{item.key}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="submitted && complaintRelateToFormControl.complaintRelateToSelection.errors?.required">
        <small class="text-danger" test_id="complaintRelateToSelection">
          {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
        </small>
      </div>

      <div class="form-group mt-20px mb-0" *ngIf="complaintConcernType == 'FinanceAgreement'">
        <label>{{complaintWidgetItem.complaintRelateTo.whatWentWrongLabel}}</label>
        <div class="select-wrapper">
          <div class="select-container">
            <mat-form-field>
              <mat-select test_id="whatWentWrongComplaintDropdownSelection" formControlName="whatWentWrongComplaintSelection"
                [ngClass]="{'has-Error': submitted && complaintRelateToSelectionForm.get('whatWentWrongComplaintSelection').errors && complaintRelateToSelectionForm.get('whatWentWrongComplaintSelection').invalid && complaintRelateToSelectionForm.get('whatWentWrongComplaintSelection').errors.required}"
                id="whatWentWrongComplaintDropdownSelection" class="form-control" disableOptionCentering panelClass="myPanelClass">
                <mat-option [value]="null" disabled></mat-option>
                <mat-option *ngFor="let complaintCategories of complaintCategoriesServicingLevelTwo" 
                  [value]="complaintCategories">{{complaintCategories}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="submitted && complaintRelateToFormControl.whatWentWrongComplaintSelection.errors?.required">
        <small class="text-danger" test_id="whatWentWrongComplaintSelection">
          {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
        </small>
      </div>

      <div class="complaints-btn-group margin-top-20">
        <button class="wss-button-secondary" test_id="previousButton" id="complaintRelateToPreviousButton"
          (click)="handlePreviousBtn()">
          {{complaintWidgetItem.complaintRelateTo.secondaryButtonText}}
        </button>
        <button class="wss-button" test_id="nextButton" id="complaintRelateToNextButton"
          (click)="handleNextBtn()">{{complaintWidgetItem.complaintRelateTo.primaryButtonText}}</button>
      </div>
    </div>
  </form>
</div>